<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('expenses') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
            :option="options" createBtnLabel="create expenses invoice"
            :tableHeight="$store.state.Settings.windowSize.y - 310" :viewSearch="true" :searchByDateRange="false"
             :printValueMethod="printValueMethod" :data="rows" :header="header">
            <!--   <template v-slot:inputs>

            </template> -->
          </DynamicTable>



        </v-col>
        <v-col cols="12" sm="12" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
export default {
  name: "ExpensesTable",

  data: () => ({
    isLoading: true,
    controlRoute: '/expenses/create-expenses',
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 15,
    searchText: '',
    from: null,
    to: null,
    date: null,
    options: null,
  }),
  components: {
    DynamicTable,
    Pagination,
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "id", type: 'text', classes: "" },
        { text: "pay for", key: "pay_for", type: 'text', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "expenses amount", key: "amount", type: 'price', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "option", key: "id", type: 'actions', classes: "" },
      ]
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      this.isLoading = true;

      this.$api.GET_METHOD(`expense?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&date=${this.date || ''}`).then((response) => {
        this.isLoading = false;
        this.rows = response.data.items.data;
        this.page = response.data.items.current_page;
        this.pagination = {
          page: response.data.items.current_page,
          totalPages: response.data.items.last_page,
          per_page: response.data.items.per_page,
          totalRows: response.data.items.total,
        };
      })

    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    printValueMethod(row) {
      const src = `src="${this.$store.state.endpointURL + `print_expense/` + row.id}?type=image"`;
      this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
