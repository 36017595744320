import Vue from "vue";
import VueRouter from "vue-router";

// Error Pages
import Error404 from "../views/ErrorPages/Error404.vue";
import Error500Vue from "../views/ErrorPages/Error500.vue";

// Authentication pages
import AuthVue from "../views/Auth/Auth.vue";
import LoginPageVue from "../views/Auth/LoginPage.vue";
import ForgetPassword from "../views/Auth/ForgetPassword.vue";

// Authorized Pages
// import Home from "../views/Home.vue";
import DashboardVue from "../views/Dashboard.vue";

// Booking

// import BookingVue from "../views/Booking/Booking.vue";
// import BookingTable from "../views/Booking/BookingTable.vue";
// import BookingControl from "../views/Booking/BookingControl.vue";

//  Requests
import RequestsVue from "../views/Requests/Requests.vue";
import RequestsTableVue from "../views/Requests/RequestsTable.vue";

// Invoice
import SalesInvoiceTableVue from "../views/SalesInvoice/SalesInvoiceTable.vue";
import SalesInvoiceVue from "../views/SalesInvoice/SalesInvoice.vue";
import SalesInvoiceControlVue from "../views/SalesInvoice/SalesInvoiceControl.vue";

//  Final Reports
import FinalReports from "../views/FinalReports/FinalReports.vue";
import FinalReportsTable from "../views/FinalReports/FinalReportsTable.vue";

//  Shift Reports
import ShiftReports from "../views/ShiftReports/ShiftReports.vue";
import ShiftReportsTable from "../views/ShiftReports/ShiftReportsTable.vue";
import SalesReports from "../views/SalesReports/SalesReports.vue";
import SalesReportsTable from "../views/SalesReports/SalesReportsTable.vue";

// Expenses
import Expenses from "../views/Expenses/Expenses.vue";
import ExpensesTable from "../views/Expenses/ExpensesTable.vue";
import ExpensesCreate from "../views/Expenses/ExpensesCreate.vue";

// Close Shift
import ShiftClosePage from "../views/Auth/ShiftClosePage.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardVue,
    redirect: {
      name: 'Home'
    },
    children: [
      // {
      //   path: "/",
      //   name: "BookingVue",
      //   component: BookingVue,
      //   children: [
      //     {
      //       path: "/",
      //       name: "BookingTable",
      //       component: BookingTable,
      //     },
      //     // {
      //     //   path: "/booking/booking-control",
      //     //   name: "BookingControl",
      //     //   component: BookingControl,
      //     // },
      //     // {
      //     //   path: "/booking/booking-control/:id",
      //     //   name: "BookingControlEdit",
      //     //   component: BookingControl,
      //     // },
      //   ],
      // },
      {
        path: "/",
        name: "Requests",
        component: RequestsVue,
        children: [
          {
            path: "/",
            name: "SalesInvoiceTable",
            component: RequestsTableVue,
          },
        ],
      },
      {
        path: "/invoice",
        name: "SalesInvoice",
        component: SalesInvoiceVue,
        children: [
          {
            path: "/invoice",
            name: "SalesInvoiceTable",
            component: SalesInvoiceTableVue,
          },
          {
            path: "/invoice-control",
            name: "SalesInvoiceControl",
            component: SalesInvoiceControlVue,
          },
          {
            path: "/invoice-control/:id",
            name: "SalesInvoiceControlEdit",
            component: SalesInvoiceControlVue,
          },
        ],
      },
      {
        path: "/final-report",
        name: "FinalReports",
        component: FinalReports,
        children: [
          {
            path: "/final-report",
            name: "FinalReportsTable",
            component: FinalReportsTable,
          },
        ],
      },
      {
        path: "/sales-report",
        name: "SalesReports",
        component: SalesReports,
        children: [
          {
            path: "/sales-report",
            name: "SalesReportsTable",
            component: SalesReportsTable,
          },
        ],
      },
      {
        path: "/shift-report",
        name: "ShiftReports",
        component: ShiftReports,
        children: [
          {
            path: "/shift-report",
            name: "ShiftReportsTable",
            component: ShiftReportsTable,
          },
        ],
      },
      // Expenses
      {
        path: "/expenses",
        name: "Expenses",
        component: Expenses,
        children: [
          {
            path: "/expenses",
            name: "ExpensesTable",
            component: ExpensesTable,
          },
          {
            path: "/expenses/create-expenses",
            name: "ExpensesCreate",
            component: ExpensesCreate,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: AuthVue,
    redirect: {
      name: 'LoginPage'
    },
    children: [
      {
        path: "/",
        name: "LoginPage",
        component: LoginPageVue,
      },
      {
        path: "/login",
        name: "LoginPagee",
        component: LoginPageVue,
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
      },
      {
        path: "/close-shift",
        name: "ShiftClosePage",
        component: ShiftClosePage,
      },
    ],
  },
  {
    path: "/error500",
    name: "Error500",
    component: Error500Vue,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
