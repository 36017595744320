<template>
  <v-card class="rounded-lg shadow justify-center pa-0" :max-height="height >= 300 ? height : 300" style="
    overflow-y: auto;
    background-color:  transparent ;
">
    <v-col cols="12" sm="12" class="pa-0">
      <v-simple-table style="overflow-x: hidden !important ;" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{ $t('service type') }}</th>
              <th>{{ $t('category') }}</th>
              <th>{{ $t('service') }}</th>
              <th>{{ $t('service price') }}</th>
              <th>{{ $t('count') }}</th>
              <th>{{ $t('delete') }}</th>
            </tr>
          </thead>
          <tbody >
          
            <tr v-for="(row, index) in invoice" :key="index" style=" animation: fadeIn 0.4s !important;">
              <td>{{ row.type_title }}</td>
              <td>{{ row.category_title }}</td>
              <td>{{ row.title }}</td>
              <td>{{ row.price | float }} {{ $t('ryal') }}</td>
              <td class="d-flex align-center justify-space-between">
                <v-btn elevation="0" color="transparent" fab @click="increment(row.id)" x-small>
                  <v-icon color="success">mdi-plus</v-icon>
                </v-btn>
                {{row.count || 0}}
                <v-btn elevation="0" color="transparent" fab @click="decrement(row.id)" x-small>
                 <v-icon color="error">mdi-minus-thick</v-icon>
                  <!-- <v-icon color="error" v-if="row.count >= 2">mdi-minus-thick</v-icon>
                  <v-icon color="error" v-else>mdi-delete</v-icon> -->
                </v-btn>
              </td>
              <td>
                <v-btn elevation="0" color="transparent" fab @click="deleteItem(index)" x-small>
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>


            <tr v-if="invoice.length != 0">
              <th colspan="2">{{ translation.total }}</th>

              <th colspan="5" class="text-center">{{ totalPrice | float }} {{ $t('ryal') }}</th>
            </tr>
            <tr v-if="invoice.length == 0" class="white">
              <th colspan="9" class="py-3" style="border : 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('no services added') |
                    capitalize
                }}</v-alert>
              </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "InvoiceData",
  data: () => ({
    height: 0,
  }),
  mounted() {
    this.height = window.innerHeight - 400
  },
  watch: {
    invoice() {

    }
  },
  computed: {
    ...mapState(["invoice", "totalPrice"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  methods: {
    ...mapActions(["deleteItemAction","increment", 'decrement']),
    deleteItem(index) {
      this.deleteItemAction(index).finally(() => { });
    },
  },
};
</script>



<style scoped lang="scss">
thead {
  tr {
    background-color: #e8e8ee !important;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(even) {
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }
  }

  &:nth-of-type(odd) {
    background-color: #f1f1f3;

    &:hover {
      background-color: #f1f1f3 !important;
    }
  }

  &:last-of-type {
    background-color: #e8e8ee;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

td,
th {
  border-left: 1px solid #e0e0e0;
  border-bottom: 0 !important;
  text-align: center !important;
}

td:last-of-type,
th:last-of-type {
  border-left: 0px solid #e0e0e0;
}
</style>

