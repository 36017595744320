<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded-md color="primary" v-bind="attrs" v-on="on" depressed>
          <span>{{ $t('select customer') }}</span>
        </v-btn>
      </template>
      <v-card relative>
        <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="dialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">{{ $t('select customer') }}</v-card-title>
        <v-divider></v-divider>



        <!-- search -->
        <v-col cols="12" class="d-flex align-center">
          <v-text-field class="my-2" outlined :placeholder="$i18n.t('customer name / mobile number')" v-model="search"
            @keydown.enter="searchReturnSales" rounded-md dense autofocus hide-details type="text"
            @click="seachResult = [], viewForm = false"></v-text-field>

          <v-btn @click="searchReturnSales" :disabled="loading" :loading="loading" class="my-2 mx-2 shadow"
            color="primary">
            <v-icon dark>mdi-magnify</v-icon>
            <span class="d-md-block d-none">{{ $t('search') }}</span>
          </v-btn>
          <v-btn @click="viewForm = true, search = ''" :disabled="viewForm" class="my-2 mx-2 shadow white--text" color="gold">
            <v-icon left dark>mdi-plus-thick</v-icon>
            <span class="d-md-block d-none">{{ $t('add new customer') }}</span>
          </v-btn>
        </v-col>
        <!-- Search result -->

        <v-col cols="12" sm="12" v-if="seachResult.length > 0 && !viewForm">
          <v-card class="backgroundW shadow pa-5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <!-- <th class="text-center">{{ $t('username') }}</th> -->
                    <th class="text-center">{{ $t('customerName') }}</th>
                    <th class="text-center">{{ $t('customerMobile') }}</th>
                    <!-- <th class="text-center">{{ $t('email') }}</th> -->
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in seachResult" :key="index">
                    <!-- <td>{{ row.username }}</td> -->
                    <td class="text-center">{{ row.user_full_name }}</td>
                    <td class="text-center">{{ row.mobile }}</td>
                    <!-- <td>{{ row.email }}</td> -->
                    <td>
                      <v-btn color="success" @click="returnData(row)" small>{{ $t('choose') }}</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <!-- Form -->
        <v-col cols="12" sm="12" v-if="viewForm">
          <v-card elevation="0" class=" pa-0">
            <v-form ref="form" v-model="valid">
              <v-row align="center" justify="end">

                <!-- <GenericInput type="text" :clearable="true" :value="form.username" @input="form.username = $event"
                  label="username" paddingY="my-1" :hideDetails="true" :cols="[12, 12, 6]">
                </GenericInput> -->
                <GenericInput type="text" :clearable="true" :value="form.user_full_name" @input="form.user_full_name = $event"
                  label="customerName" paddingY="my-1" :hideDetails="true" :cols="[12, 12, 6]">
                </GenericInput>
                <GenericInput type="number" :clearable="true" :value="form.mobile" @input="form.mobile = $event"
                  label="customerMobile" paddingY="my-1" :hideDetails="true" :cols="[12, 12, 6]">
                </GenericInput>
                <!-- <GenericInput type="email" :clearable="true" :value="form.email" @input="form.email = $event"
                  label="email" paddingY="my-1" :hideDetails="true" :cols="[12, 12, 6]">
                </GenericInput> -->

                <v-col cols="12" sm="auto">
                  <v-btn color="success" :min-width="100" :disabled="!valid" :loading="loading" @click="addCustomer"
                    class="shadow">{{ $t('save') }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>

        <v-col cols="auto" v-if="loading">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('Loading data') }}</p>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import GenericInput from '../GenericInput.vue';

export default {
  name: "CustomerModal",
  props: {
    backValueMethod: { type: Function },
  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  watch: {
    customerType() {
      this.seachResult = [];
      this.$refs.form.reset();
    },
    dialog() {
      this.viewForm = false;
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      search: "",
      seachResult: [],
      viewForm: false,
      form: {
        user_full_name: "",
        mobile: "",
        // email: "",
        // username: "",
      },
    };
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyC") {
        this.dialog = true;
      }
      if (e.code == "Escape") {
        this.dialog = false;
      }
    });
  },
  methods: {
    returnData(id) {
      this.backValueMethod(id);
      this.dialog = false;
      this.viewForm = false;
    },
    searchReturnSales() {
      this.loading = true;
      this.$api.GET_METHOD(`search_customer?word=${this.search}`).then((response) => {
        this.loading = false;
        // console.log(response);
        this.seachResult = response;
      });
    },
    addCustomer() {
      this.loading = true;
      this.$api.POST_METHOD("add_customer", this.form).then((response) => {
        this.loading = false;
        if (response.check) {
          this.backValueMethod(response.data);
          this.$refs.form.reset();
          this.dialog = false;
          this.viewForm = false;
        }
      });
    }
  },
  components: { GenericInput }
};
</script>
