<template>
  <section>
    <!-- Page Is Loading -->
    <v-container fluid v-if="isLoading">
      <SkeletonInvoiceLoader></SkeletonInvoiceLoader>
    </v-container>

    <!-- Page Loaded -->
    <v-container fluid v-if="!isLoading">
      <v-row>

        <!-- Types && Services -->
        <v-col cols="12" md="6">
          <!-- Types -->
          <v-col cols="12" class="white shadow rounded-lg pa-5" sm="12">
            <div class="text--disabled subtitle-1 d-none mb-5">{{ $t('service type') }}</div>
            <ServicesType :types="serviceTypes" :disabledTypes="disabledTypes" v-if="serviceTypes.length > 0"
              :activeType="activeType" :backValueMethod="setActiveType" />
          </v-col>

          <!-- Categories -->
          <v-col cols="12" class="white shadow my-5 rounded-lg pa-5" sm="12">
            <div class="text--disabled subtitle-1 d-none mb-5">{{ $t('Category') }}</div>
            <CategoriesBar :activeType="activeType" :categories="categories" :activeCategory="activeCategory"
              v-if="categories.length > 0" :backValueMethod="setActiveCategory" />
          </v-col>
          <!-- Services -->
          <v-col cols="12" class="pa-0 my-5" sm="12">
            <v-card class="white shadow rounded-lg d-sm-flex d-block pa-5">
              <v-row align="center">
                <v-col cols="12" class="d-none">
                  <div class="text--disabled subtitle-1">{{ $t('services') }}</div>
                </v-col>
                <v-col cols="6" lg="3" md="auto" sm="auto"
                  v-for="(service, index) in services.filter(service => service.category_id == activeCategory)"
                  :key="index" class="paddingX">

                  <!-- <v-btn depressed
                    :class="$global.FilterArrayOfObject(invoice, 'id', service.id).length > 0 ? 'gradients my-1' : 'gradients'"
                    class="rounded-md subtitle-1" @click="addService(service)" style="width: 100%" elevation="0"
                    :height="50.06" max-height="100">
                    <p class="ma-0 py-1">
                      <small>{{ service.title }}</small>
                    </p>
                  </v-btn> -->

                  <button v-ripple
                    :class="$global.FilterArrayOfObject(invoice, 'id', service.id).length > 0 ? 'gradients my-1' : 'gradients'"
                    class="rounded-lg px-2 py-2 subtitle-1" @click="addService(service)"
                    style="width: 100%; min-height: 50.06px;" elevation="0">
                    <small>{{ service.title }}</small>
                  </button>


                </v-col>
                <!-- If  not select category -->
                <v-col v-if="!activeCategory" class="text-center">
                  <span>{{ $t("please select category") }}</span>
                </v-col>
                <!-- If No services in active category -->
                <v-col
                  v-if="services.filter(category => category.category_id == activeCategory).length == 0 && activeCategory"
                  class="text-center">
                  <span>{{ $t("notAvail") }} {{ $t("services") }} {{ $t("here") }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-col>

        <!-- Invoice Details -->
        <v-col cols="12" md="6">

          <v-row justify="space-between" align="center">
            <v-col class="mb-lg-5 mt-2 mx-lg-2 pa-0 pa-sm-2" cols="auto">
              <p class="ma-0">
                <span v-if="!customer" class="text--disabled">{{ $t('no customer selected') }}</span>
                <span v-if="customer">{{ customer }}</span>
              </p>
            </v-col>
            <v-col class="mb-5 mt-2 mx-2" cols="auto" :key="`customer${invoiceKey}`">
              <div class="d-flex align-center">
                <CustomerModal :backValueMethod="setCustomer"></CustomerModal>
              </div>
            </v-col>
          </v-row>


          <!-- Invoice Table -->
          <div class="mt-2">
            <InvoiceData></InvoiceData>
          </div>

          <!-- Control -->
          <v-col cols="12" class="pa-0 mt-2">
            <v-card class="shadow rounded-lg justify-center pa-5" :key="`payment${invoiceKey}`">
              <v-row align="center">


                <v-col cols="6" lg="3" md="4">
                  <div class="light rounded-lg cursor_pointer body-2 pa-3" @click="form.payment_id = 20"
                    :class="form.payment_id != 20 ? 'text--disabled' : 'font-weight-bold'">
                    <v-icon color="#0A6ADA" :disabled="form.payment_id != 20" size="17">mdi-check-circle</v-icon>
                    {{ $t('payCash') }}
                  </div>
                </v-col>
                <v-col cols="6" lg="3" md="4">
                  <div class="light rounded-lg cursor_pointer body-2 pa-3" @click="form.payment_id = 21"
                    :class="form.payment_id != 21 ? 'text--disabled' : 'font-weight-bold'">
                    <v-icon color="#0A6ADA" :disabled="form.payment_id != 21" size="17">mdi-check-circle</v-icon>
                    {{ $t('payBank') }}
                  </div>
                </v-col>
                <v-col cols="6" lg="6">
                  <SalesAgentModal :sales_agents="sales_agents" :sellar="salesAgent" :backValueMethod="setAgent">
                  </SalesAgentModal>
                </v-col>


                <GenericInput type="select" :clearable="true"
                  :lookups="[{ id: 1, name: $i18n.t('amount') }, { id: 2, name: $i18n.t('percentage') }]"
                  selected_label="name" selected_prop="id" :value="form.discount_type"
                  @input="form.discount_type = $event" label="discount type" paddingY="my-1" :isLoading="false"
                  :hideDetails="true" :cols="[12, 6, 3]" />
                <GenericInput :type="form.discount_type == 2 ? 'percentage' : 'number'" :value="form.discount"
                  @input="form.discount = $event" paddingY="my-1"
                  :label="form.discount_type == 2 ? 'percentage' : 'amount'" :required="false" :hide-details="true"
                  :isLoading="false" :cols="[12, 6, 3]" />

                <v-col cols="12" class="py-0" v-if="form.payment_id == null && invoice.length > 0">
                  <v-alert type="info" outlined dense>
                    {{$t('please select payment type')}}
                  </v-alert>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0 mt-2" v-if="discountNotValid == true">
            <v-alert type="error" outline class="rounded-lg">
              {{ $t('discount not valid') }}
            </v-alert>
          </v-col>


          <!-- Invoice Summery -->
          <v-col cols="12" class="pa-3 mt-2">
            <v-row align="center">

              <v-col cols="auto" lg="3">
                <h5>{{ $t("without vat") }}</h5>
                <h5 class="text--disabled">
                  <strong>{{ totalPrice - (totalPrice - (totalPrice / 1.15)) | float }} {{ $t('ryal') }}</strong>
                </h5>
              </v-col>
              <v-col cols="auto" lg="3">
                <h5>{{ $t("vat") }}</h5>
                <h5 class="text--disabled">
                  <strong>{{ totalPrice - (totalPrice / 1.15) | float }} {{ $t('ryal') }}</strong>
                </h5>
              </v-col>

              <v-col cols="auto" lg="3">
                <h5>{{ $t("finalCash") }}</h5>
                <h5 class="text--disabled">
                  <strong>{{ totalPrice | float }} {{ $t('ryal') }}</strong>
                </h5>
              </v-col>
              <v-col cols="12">
                <v-btn class="shadow mi-start-auto d-block" :loading="loading" width="140" :disabled="!saveValid"
                  @click="saveInvoice" large color="success">
                  {{ $t('save invoice') }}</v-btn>
              </v-col>
            </v-row>
          </v-col>


        </v-col>

      </v-row>

    </v-container>
  </section>
</template>


<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceData from "@/components/InvoiceData";
import SkeletonInvoiceLoader from "@/components/SkeletonInvoiceLoader.vue";
import ServicesType from "@/components/ServicesType.vue";
import CategoriesBar from "@/components/CategoriesBar.vue";
import GenericInput from "@/components/GenericInput.vue";
import CustomerModal from "@/components/modals/CustomerModal.vue";
import SalesAgentModal from "@/components/modals/SalesAgentModal.vue";
export default {
  name: "SalesInvoiceControl",
  data: () => ({
    invoiceKey: 1,
    loading: false,
    isEdit: false,
    activeType: 1,
    activeCategory: null,
    isLoading: true,
    disabledTypes: false,
    services: [],
    categories: [],
    branches: [],
    serviceTypes: [],
    times: [],
    customers: [],
    locations: [],
    payment_ids: [],
    status: [],
    sales_agents: [],
    salesAgent: "",
    form: {
      branch_id: null,
      request_date: null,
      user_id: null,
      payment_id: null,
    },
    customer: null,
    today: null,
  }),
  components: {
    InvoiceData,
    SkeletonInvoiceLoader,
    ServicesType,
    CategoriesBar,
    GenericInput,
    CustomerModal,
    SalesAgentModal
  },
  computed: {
    ...mapState(["invoice", "totalPrice"]),
    valid() {
      if (this.form.branch_id && this.form.request_date && this.form.user_id && this.invoice.length > 0) {
        return true
      }
      else {
        return false
      }
    },
    discountNotValid() {
      if (this.form.discount_type == 2 && this.form.discount > 100) {
        return true
      }
      if (this.form.discount_type == 1 && this.form.discount > this.totalPrice) {
        return true
      }
      else {
        return false
      }
    },
    saveValid() {
      if (this.discountNotValid == false && this.valid == true && this.form.payment_id) {
        return true
      }
      else {
        return false
      }
    }
  },
  destroyed: function () {
    this.clearInvoice().finally(() => { });
  },

  mounted() {
    this.getData();
    this.formatDate();
  },
  watch: {
    "form.user_id"() {
      this.customer =  this.form.user_id ? this.$global.FilterArrayOfObjectElement(this.customers, 'user_id', this.form.user_id, 'user_full_name') : null
    },
    activeType() {
      this.activeCategory = null
    },
    invoice() {
      // if (this.invoice.length > 0) {
      //   this.disabledTypes = true;
      // } else {
      //   this.disabledTypes = false;
      // }
    }
  },
  methods: {
    ...mapActions(["addInvoiceAction", "clearInvoice"]),
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.today = [year, month, day].join('-');
      this.form.request_date = [year, month, day].join('-');
    },
    addService(service) {
      // service.count = Number(service.count || 0) + 1
      this.addInvoiceAction(service).finally(() => { });
    },

    getData() {

      if (this.$route.params.id) {
        console.log(this.$route.params.creationData);
        this.isEdit = true;



        this.isLoading = true;
        this.$api.GET_METHOD(`requests/create`).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.serviceTypes = response.data.types;
            this.times = response.data.times;
            this.status = response.data.status;

            this.payment_ids = response.data.payment_ids;
            this.locations = response.data.locations;
            this.customers = response.data.customers;
            this.categories = response.data.categorys;
            this.branches = response.data.branchs;
            this.services = response.data.services;
            this.sales_agents = response.data.sales_agents || [];
  

            let services = this.$route.params.creationData.services.split(',').map(i => Number(i));

            this.form = {
              request_id: this.$route.params.creationData.id,
              request_date: this.$route.params.creationData.request_date,
              branch_id: this.$route.params.creationData.branch_id,
              type_id: this.$route.params.creationData.type_id,
              user_id: this.$route.params.creationData.user_id,
              payment_id: this.$route.params.creationData.payment_id,
              status_id: this.$route.params.creationData.status_id,
              services: this.$route.params.creationData.user_id,
              discount: null,
              discount_type: null
            }


            services.forEach(serviceID => {
              let selectedService = this.$global.FilterArrayOfObject(response.data.services, 'id', serviceID);
              selectedService.forEach(row => {
                row.count = 1;
              })
              this.addInvoiceAction(selectedService).finally(() => { });
            })



          }
        })


        // this.$api.GET_METHOD(`requests/${this.$route.params.id}`).then((response) => {
        //   this.isLoading = false;
        //   if (response.check) {
        //     this.serviceTypes = response.data.types;
        //     this.times = response.data.times;
        //     this.status = response.data.status;
        //     this.payment_ids = response.data.payment_ids;
        //     this.locations = response.data.locations;
        //     this.customers = response.data.customers;
        //     this.categories = response.data.categorys;
        //     this.branches = response.data.branchs;
        //     this.services = response.data.services;
        //     this.activeType = response.data.data.type_id;
        //     response.data.data.services = response.data.data.services.split(',').map(i => Number(i));
        //     response.data.data.services_names = response.data.data.services_names.split(',').map(i => i = { 'title': i });
        //     this.form = response.data.data;
        //     response.data.data.services.forEach(serviceID => {
        //       let selectedService = this.$global.FilterArrayOfObject(response.data.services, 'id', serviceID);
        //       selectedService.forEach(row => {
        //         row.count = 1;
        //       });
        //       this.addInvoiceAction(selectedService).finally(() => { });
        //     });

        //   }
        // })
      }
      else {
        this.isLoading = true;
        this.$api.GET_METHOD(`requests/create`).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.serviceTypes = response.data.types;
            this.times = response.data.times;
            this.status = response.data.status;

            this.payment_ids = response.data.payment_ids;
            this.locations = response.data.locations;
            this.customers = response.data.customers;
            this.categories = response.data.categorys;
            this.branches = response.data.branchs;
            this.services = response.data.services;
            this.sales_agents = response.data.sales_agents || [];

            this.salesAgent = this.sales_agents[0]
            this.form.branch_id = response.data.branchs[0].id;
            this.form.user_id = 100;
          }
        })
      }

    },
    setCustomer(returnedValue) {
      this.customer = returnedValue.user_full_name || returnedValue.name;
      this.form.user_id = returnedValue.user_id || returnedValue.id;
    },
    setAgent(returnedValue) {
      console.log('returnedValue',returnedValue);
      this.salesAgent = returnedValue;
    },
    saveInvoice() {
      this.loading = true;
      const servicesIDs = this.invoice.map(object => object.id);


      if (this.isEdit) {

        let requestBody = {
          request_date: this.form.request_date,
          request_id: this.form.request_id,
          branch_id: this.form.branch_id,
          type_id: this.activeType,
          user_id: this.form.user_id,
          sales_agent_id: this.salesAgent.id,
          payment_id: this.form.payment_id,
          discount: this.form.discount,
          discount_type: this.form.discount_type,
          // status_id: 1,
          services: servicesIDs,
          pos_services: this.invoice,
        }
        this.$api.POST_METHOD(`create_invoice`, requestBody).then((response) => {
          this.loading = false;
          if (response.check) {
            this.step = 1;
            this.form = {
              request_date: this.today,
              branch_id: null,
              type_id: 1,
              user_id: 1,
              payment_id: null,
              status_id: 1,
              services: [],
              discount: null,
              discount_type: null,
            }
            // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + response.data);
            const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + response.data}?type=image"`;
            this.$api.PrintInvoice(src, response.data)
            this.$router.push('/')
          }
        })
      } else {
        let requestBody = {
          request_date: this.form.request_date,
          branch_id: this.form.branch_id,
          type_id: this.activeType,
          user_id: this.form.user_id,
          sales_agent_id: this.salesAgent.id,
          payment_id: this.form.payment_id,
          status_id: 1,
          services: servicesIDs,
          pos_services: this.invoice,
          discount: this.form.discount,
          discount_type: this.form.discount_type,
        }
        this.$api.POST_METHOD(`create_invoice`, requestBody).then((response) => {
          this.loading = false;
          if (response.check) {
            this.step = 1;
            this.form = {
              request_date: this.today,
              branch_id: null,
              type_id: 1,
              user_id: 100,
              status_id: 1,
              services: [],
              payment_id: null,
              discount: null,
              discount_type: null,
            }
            this.form.branch_id = this.branches[0].id;
            // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + response.data);
            const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + response.data}?type=image"`;
            this.$api.PrintInvoice(src, response.data)
            this.clearInvoice().finally(() => { });
          }
        })
      }


    },
    setActiveType(type) {
      this.activeType = type
    },
    setActiveCategory(category) {
      // console.log(category);
      this.activeCategory = category
    }
  },
};
</script>

<style  scoped lang="scss">
.paddingX {
  padding: 3px;
}
</style>