// import axios from "axios";
export default {
  namespaced: true,
  state: {
    invoice: [],
    goldType: [],
    totalPrice: 0,
    totalVat: 0,
    priceWithoutVat: 0,
    totalWeight: 0,
    totalPieces: 0,
    totalPricePieces: 0,
    customerType: 1,
    isTypesLoading: false,
    error: null,
  },
  getters: {
    invoice: (state) => {
      return state.invoice;
    },
    goldType: (state) => {
      return state.goldType;
    },
  },
  mutations: {
    UPDATE_INVOICE(state, invoice) {
      state.invoice = invoice;
      var total = state.invoice.reduce((oldValue, newValue) => {
        return (
          Number(+oldValue) + Number(+newValue.price) * Number(+newValue.count)
        );
      }, 0);
      state.totalPrice = total;
    },
    UPDATE_TYPES(state, goldType) {
      state.goldType = goldType;
    },
    UPDATE_CUSTOMER_TYPE(state, type) {
      state.customerType = type;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_INVOICE(state) {
      state.invoice = [];
      state.goldType = [];
      state.totalPrice = 0;
      state.totalVat = 0;
      state.priceWithoutVat = 0;
      state.totalWeight = 0;
      state.isTypesLoading = false;
      state.error = null;
    },
  },
  actions: {
    changeCustomerType({ commit }, data) {
      commit("UPDATE_CUSTOMER_TYPE", data);
      commit("CLEAR_INVOICE");
    },
    addInvoiceAction({ commit, state }, data) {
      if (state.invoice.some((item) => item.id == data.id) == true) {
        //exist
        const getIndex = state.invoice.findIndex((item) => item.id == data.id);
        // console.log("getIndex", state.invoice[getIndex]);
        state.invoice[getIndex].count = state.invoice[getIndex].count + 1;
        commit("UPDATE_INVOICE", state.invoice);
      } else {
        // not exist
        data.count = 1;
        commit("UPDATE_INVOICE", state.invoice.concat(data));
      }
    },

    increment({ commit, state }, id) {
      const getIndex = state.invoice.findIndex((item) => item.id == id);
      state.invoice[getIndex].count = state.invoice[getIndex].count + 1;
      commit("UPDATE_INVOICE", state.invoice);
    },
    decrement({ commit, state }, id) {
      const getIndex = state.invoice.findIndex((item) => item.id == id);
      if (state.invoice[getIndex].count >= 2) {
        state.invoice[getIndex].count = state.invoice[getIndex].count - 1;
        commit("UPDATE_INVOICE", state.invoice);
      } else {
        state.invoice.splice(getIndex, 1);
        commit("UPDATE_INVOICE", state.invoice);
      }
    },
    clearInvoice({ commit }) {
      commit("CLEAR_INVOICE");
    },
    deleteItemAction({ state, commit }, index) {
      state.invoice.splice(index, 1);
      commit("UPDATE_INVOICE", state.invoice);
    },
  },
};
