import Vue from "vue";
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
   name: '_blank',
   timeout: 2350, 
   width : 100,
   height : 100,
   autoClose: true, 
   windowTitle: 'فاتورة TheConcept - POS', 
      specs: [
      'fullscreen=no',
      'titlebar=no',
      'scrollbars=no'
    ],
 }
Vue.use(VueHtmlToPaper ,options);
