<template>
  <section>
    <v-col cols="12" class="pa-0" sm="12">
      <v-row align="center">
        <!-- Loop -->
        <v-col cols="6" xl="3" lg="4" md="4" class="category" v-for="(type, index) in types" :key="index">
          <div class="light rounded-lg cursor_pointer body-2 pa-3" v-on:click="selectType(type)"
            :class="active != type.id ? 'text--disabled' : 'font-weight-bold'">
            <v-icon color="#0A6ADA" size="17" :disabled="active != type.id">mdi-check-circle</v-icon>
            {{ type.title }}
          </div>

        </v-col>
        <!-- End Of loop -->
      </v-row>
    </v-col>
  </section>
</template>


<script>
export default {
  name: "ServicesType",
  props: {
    backValueMethod: { type: Function },
    types: { default: [] },
    activeType: { default: 1 },
    disabledTypes: { default: false },
  },
  data: () => ({
    active: null
  }),
  computed: {

  },
  watch: {
    active() {
      this.backValueMethod(this.active)
    }
  },
  mounted() {
    this.active = this.activeType
  },
  beforeDestroy: function () {

  },
  methods: {
    selectType(type) {
      if (this.disabledTypes == false) {
        this.active = type.id
      }
    }
  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 3px;
  min-width: 110px;
}
</style>
