<template>
  <section>
    <v-col cols="12" class="pa-0" sm="12">
      <v-row align="center">
        <!-- Loop -->
        <v-col cols="6" xl="3" lg="4" md="auto" class="category"
          v-for="(category, index) in categories.filter(category => category.types == activeType)" :key="index">
          <div class="light rounded-lg cursor_pointer body-2 pa-3" v-on:click="selectCategory(category)"
            :class="active != category.id ? 'text--disabled' : 'font-weight-bold'">
            <v-icon color="#0A6ADA" size="17" :disabled="active != category.id">mdi-check-circle</v-icon>
            {{ category.title }}
          </div>
        </v-col>
        <!-- End Of loop -->

        <v-col v-if="categories.filter(category => category.types == activeType).length == 0" class="text-center">
          <span>{{ $t("notAvail") }} {{ $t("categories") }} {{ $t("here") }}</span>
        </v-col>


      </v-row>
    </v-col>
  </section>
</template>


<script>
export default {
  name: "CategoriesBar",
  props: {
    backValueMethod: { type: Function },
    categories: { default: [] },
    activeCategory: { default: 1 },
    activeType: { default: null },
    disabledCategory: { default: false },
  },
  data: () => ({
    active: null
  }),
  computed: {

  },
  watch: {
    active() {
      this.backValueMethod(this.active)
    },
    activeCategory(){
       this.active = this.activeCategory
    }
  },
  mounted() {
    this.active = this.activeCategory
  },
  beforeDestroy: function () {

  },
  methods: {
    selectCategory(category) {
      if (this.disabledCategory == false) {
        this.active = category.id
      }
    }
  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 3px;
  min-width: 110px;
}
</style>
