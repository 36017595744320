<template>
  <v-row justify="center">
    <v-card relative width="500" style="max-height: 98vh;overflow-y: auto;">
      <v-card-title class="text-h6">{{ $t('startShift') }}</v-card-title>
      <v-divider></v-divider>
      <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
        <v-col cols="12" sm="12">
          <h5 class="mb-5">{{ $t('changeBranch') }}</h5>
          <v-btn-toggle v-model="toggle_branch" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
            <v-row>
              <v-btn v-for="(branch, index) in branches" :key="index" v-on:click="branch_id = branch.branch_id" :value="branch.branch_id"
                class="rounded ma-1" color="gray lighten-5" elevation="0">
                <v-icon color="blue accent-4" :disabled="branch_id != branch.branch_id" left>mdi-check-circle</v-icon>
                {{ branch.branch_title }}
              </v-btn>
            </v-row>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" class="py-0">
          <h5 class="mb-3">{{ $t('finalCash') }}</h5>
          <v-text-field hide-details="auto"  @keydown.enter="startShift()" class="my-2 rounded grey lighten-5" filled v-model="cash" :value="0" dense
            rounded type="number"></v-text-field>
        </v-col>

        <v-col cols="12" class="py-4">
          <v-btn color="gold" large @click="startShift" :loading="loading" class="shadow white--text"
            style="width: 100%">{{ $t('startShift') }}</v-btn>
        </v-col>
      </v-form>
    </v-card>
  </v-row>
</template>



<style scoped lang="scss">

</style>



<script>

export default {
  name: "StartShift",
  props: {
    branches: { type: {} },
  },
  computed: {
  },
  mounted() {
    // console.log("branches", this.branches);
    this.branch = Object.keys(this.branches)[0]
    this.toggle_branch = Object.keys(this.branches)[0]
  },
  watch: {
  },
  data() {
    return {
      dialog: true,
      valid: false,
      loading: false,
      branch_id: null,
      cash: null,
      toggle_branch: 1,
    };
  },
  methods: {
    startShift() {
      this.loading = true;
      // this.$refs.form.validate()
      const requestBody = {
        branch_id: Number(+this.branch_id),
        start_cash: Number(+this.cash) || 0,
      }
      this.$api.POST_METHOD("start_shift", requestBody).then((response) => {
        this.loading = false;
        this.$api.SET_AUTH(response.data);
        this.$router.push('/')
      })
    },

  },
};
</script>
