<template>

  <v-dialog v-model="dialog" overlay-opacity="0.75"  max-width="600">
    <v-card relative class="backgroundW">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="red5" text @click="closeDialog">
        <v-icon color="red5">mdi-close</v-icon>
      </v-btn>

      <v-card class="pa-6  shadow-none">
        <v-row align="center" justify="end" class="mt-2">

          <v-col cols="4">
            <v-card class="shadow step_card" @click="printRequest">

              <div class="subtitle-1 gray7--text">
                {{ $t('view request') | capitalize }}
              </div>

            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card class="shadow step_card" @click="printInvoice">

              <div class="subtitle-1 gray7--text">
                {{ $t('view invoice') | capitalize }}
              </div>

            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="shadow step_card" @click="printReceipt">

              <div class="subtitle-1 gray7--text">
                {{ $t('view receipt') | capitalize }}
              </div>

            </v-card>
          </v-col>



        </v-row>
      </v-card>

    </v-card>
  </v-dialog>

</template>



<style scoped lang="scss">
.step_card {
  border: 1px solid #e5e5e5;
  padding: 1rem;
  text-align: center;
  transition: 0.35s;
  cursor: pointer;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: #e5e5e5;
    border: 1px solid #bf9d5f
  }

  &:hover {
    background-color: #e5e5e5;
  }

}
</style>



<script>


export default {
  name: "OptionModal",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    row: { default: null },
  },
  computed: {


  },
  watch: {
    dialog(){
      if(this.dialog == false){
          this.backValueMethod(false);
      }
    }
  },
  components: {

  },
  data: () => ({
    isLoading: false,
    valid: false,


  }),
  methods: {
    closeDialog(status = false) {
      this.backValueMethod(status);
    },
    printInvoice() {
      window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_invoice/` + this.row.id);
      this.closeDialog(false)
    },
    printReceipt() {
      window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt/` + this.row.id);
      this.closeDialog(false)
    },
    printRequest() {
      window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/print_request/` + this.row.id);
      this.closeDialog(false)
    },
    save() {
      this.isLoading = true;
      this.form.attachments = this.form.attachments || []
      this.$api.POST_METHOD(`save_task_log/${this.task.id}`, this.form).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.backValueMethod(true)
        }
      })
    }
  },
  mounted() {

  }
};
</script>
