<template>
  <v-container fluid>
    <v-row justify="space-between" align="center">
      <v-col col="12" lg="auto" md="auto" sm="auto">
        <v-row align="center">
          <v-col cols="auto" class="px-5">
            <router-link to="/">
              <img src="../assets/img/png/logo.png" style="max-height: 50px;" alt="TheConcept-POS" />
            </router-link>
          </v-col>
          <!-- 
          <v-col cols="auto" class="d-md-block d-none px-1">
            <v-btn to="/" active-class="secondary" style="height: 40px" :min-width="85" rounded small depressed
              color="transparent">{{ $t('home') }}</v-btn>
          </v-col> -->

          <v-col cols="auto" class=" px-1">
            <v-btn to="/" active-class="secondary" exact style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('requests') }}</v-btn>
          </v-col>

          <v-col cols="auto" class=" px-1">
            <v-btn to="/invoice" active-class="secondary" exact style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('invoices') }}</v-btn>
          </v-col>
          <v-col cols="auto" class=" px-1">
            <v-btn to="/invoice-control" active-class="secondary" exact style="height: 40px" rounded :min-width="85"
              small color="transparent" depressed>{{ $t('new invoice') }}</v-btn>
          </v-col>

          <v-col cols="auto" class=" px-1">
            <v-btn to="/final-report" active-class="secondary" exact style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('final report') }}</v-btn>
          </v-col>
          <v-col cols="auto" class=" px-1">
            <v-btn to="/sales-report" active-class="secondary" exact style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('sales reports') }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1">
            <v-btn to="/expenses" active-class="secondary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("expenses") }}</v-btn>
          </v-col>

          <!-- <v-col cols="auto" class=" px-1">
            <v-btn to="/shift-report" active-class="secondary" exact style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('shifts') }}</v-btn>
          </v-col> -->


        </v-row>
      </v-col>
      <v-col col="auto" lg="auto" md="auto" sm="auto">
        <v-row align="center" justify="end">



          <v-col cols="12" md="auto" class="d-none d-md-block" sm="auto">
            <v-btn v-on:click="changeLanguage" small fab class="bg-white d-block ma-auto" elevation="0">
              <img src="../assets/img/svg/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="TheConcept-POS" />
              <img src="../assets/img/svg/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="TheConcept-POS" />
            </v-btn>
          </v-col>

          <v-col cols="12" md="auto" class="d-none d-md-block" sm="auto">
            <v-btn small fab elevation="0" v-fullscreen.teleport="options">
              <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="auto"  sm="4">
            <v-btn small rounded class="bg-white" style="height: 40px" to="/close-shift" depressed>
              <span class="d-lg-block d-none">{{$t('endShift')}}</span>
              <img class="mx-1" src="../assets/img/svg/logout.svg" alt="Qarat-POS" />
            </v-btn>
          </v-col>
          <v-col cols="4" md="auto" sm="auto" class="py-0">
            <v-btn @click="logoutDialog = true" rounded class="bg-white" depressed>
              <!-- <img class="mx-1" src="@/assets/img/svg/log.svg" alt="TheConcept-POS" /> -->
              <span class="d-lg-block d-none">{{ $t('logout') }}</span>
              <v-icon size="18" right color="red4">mdi-logout</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <LogoutConfirmation :dialog="logoutDialog" :backValueMethod="logout"></LogoutConfirmation>

  </v-container>
</template>




<script>

import LogoutConfirmation from "./modals/LogoutConfirmation.vue";
export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
        target: '.directive-fullscreen-wrapper',
        pageOnly: this.pageOnly,
        teleport: this.teleport
      }
    }
  },
  data() {
    return {
      logoutDialog: false,
      fullscreen: false,
    };
  },
  components: {

    LogoutConfirmation
  },

  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    logout(status) {
      if (status == true) {
        this.$api.LOGOUT(true)
      }
      this.logoutDialog = false
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
