<template>
  <v-app v-resize="onResize">
    <router-view></router-view>
    <SpeedTest v-if="$store.state.splashScreen == false" />
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
import SpeedTest from "./components/SpeedTest.vue";
export default {
  name: "App",
  components: { SpeedTest },
  data: () => ({
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  created() {
    document.addEventListener('swUpdated', this.swUpdated, { once: true });
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  mounted() {
    console.log('%cThe Concept POS', 'background: #222; color: #bf9d5f; font-size : 60px; font-weight : bold');
    console.log("%cThis is a browser feature intended for developers, Please Close it", 'background: #222; color: #f70000; font-size : 28px; font-weight : bold');
    console.log("%cAll Copyrights Cloud Secrets © 2022", 'background: #222; color: #FFFFFF; font-size : 20px;');

    window.addEventListener("keydown", e => {
      // console.log(e);
      if (e.altKey == true && e.key == "1") {
        if (this.$global.CheckAction("06-008", 1)) {
          this.$router.push('/sales/sales-invoice-QTY')
        } else {
          this.$router.push('/sales/sales-invoice-Piece')
        }
      }
      if (e.altKey == true && e.key == "2") {
        this.$router.push('/purchases/purchases-invoice')
      }
      if (e.altKey == true && e.key == "3") {
        if (this.$global.CheckAction("06-009", 1)) {
          this.$router.push('/return-sales/create-return-sales')
        } else {
          this.$router.push('/return-sales/create-return-sales-piece')
        }
      }
      if (e.altKey == true && e.key == "4") {
        this.$router.push('/notice-debtor/create-notice-debtor')
      }
      if (e.altKey == true && e.key == "5") {
        this.$router.push('/notice-creditor/create-notice-creditor')
      }
      // if (e.altKey == true && e.key == "1") {
      //   this.$router.push('/')
      // }
      // if (e.altKey == true && e.key == "2") {
      //   this.$router.push('/sales')
      // }
      // if (e.altKey == true && e.key == "3") {
      //   this.$router.push('/sales/sales-invoice-QTY')
      // }
      // if (e.altKey == true && e.key == "4") {
      //   this.$router.push('/purchases')
      // }
      // if (e.altKey == true && e.key == "5") {
      //   this.$router.push('/purchases/purchases-invoice')
      // }
      // if (e.altKey == true && e.key == "6") {
      //   this.$router.push('/return-sales')
      // }
      // if (e.altKey == true && e.key == "7") {
      //   this.$router.push('/return-sales/create-return-sales')
      // }
    });
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      this.$global.ShowModal('info', this.$i18n.t('New update is available, this page will refresh'));
      this.refreshApp();
    },
    swUpdateFound(event) {
      console.log('swUpdateFound', event);
      this.$global.ShowModal('info', this.$i18n.t('New update is downloading'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onResize() {
      this.$store.state.Settings.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
};
</script>
