<template>
  <v-container fluid class="bgAuth d-flex align-center justify-center pa-0" style="height: 100vh">
    <div class="bgDim">
    </div>
    <v-col cols="11" lg="4" md="6" sm="7">
      <v-card v-if="!startShift" class="login-card">
        <div class="header">
          <img src="@/assets/img/png/logo.svg" class="mt-n5" height="80" alt="TheConcept-POS" />
        </div>
        <v-col cols="11" class="ma-auto mt-n8 rounded-lg d-block white" style="position: relative !important; z-index: 55;">
          <h3 class="text-center mt-2 mb-4">{{ $t("welcome back in the concept") }}</h3>
          <v-form ref="form" class="row align-items-center justify-end flex-row" v-model="valid" lazy-validation>
            <!-- <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("country code") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled
                v-model="country_code" rounded @keydown.enter="login()" dense
                prepend-inner-icon="mdi-account-check" type="text"></v-text-field>
            </v-col> -->
            <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("username") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled
                v-model="username" rounded @keydown.enter="login()" dense
                prepend-inner-icon="mdi-account-check" type="text"></v-text-field>
            </v-col>
            <v-col cols="12" class="px-6">
              <h5 class="mb-3">{{ $t("password") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled required
                v-model="password" @keydown.enter="login()" rounded dense
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                prepend-inner-icon="mdi-account-key" @click:append="show = !show"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" class=" px-4 pa-0 d-flex align-items-center">
              <!-- <v-btn plain height="auto" color="primary--text" to="/forget-password">
                {{ $t("Forget Password") }} {{ $t("?") }}
              </v-btn> -->
            </v-col>
            <v-col cols="6" sm="6" class="py-5 px-6">
              <v-btn @click="login" :loading="loading" :disabled="!valid" color="gold" class="d-block white--text mi-start-auto shadow"
                >{{ $t("login") }}</v-btn>
            </v-col>
          </v-form>
        </v-col>
      </v-card>
      <StartShift v-if="startShift" :branches="branches"></StartShift>
    </v-col>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/png/barberBG.jpg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: relative;
  .bgDim{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:  rgba(0,0,0,0.5) ;
  }
  .login-card {
    background: #efefef;
    padding-bottom: 1.5rem;
    border-radius: 15px;

  // overflow: hidden;

    .header {
      background-color: #262525;
      min-height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -2px;
      border-top-left-radius: 15px  !important;
      border-top-right-radius: 15px !important;
    }
  }
}
</style>
<script>

import StartShift from "@/components/modals/StartShift.vue";
export default {
  name: "LoginPage",
  computed: {
  },
  watch: {
    companyNumber() {
      this.$store.commit('UPDATE_CLIENT', this.companyNumber || null)
    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
    document.title = this.$i18n.t("The Concept - Login");
  },
  components: {
    StartShift
  },
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      country_code: "+966",
      username: null,
      password: null,
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()


      if (this.valid == true) {
        const requstBody = {
          username: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requstBody).then((response) => {
          this.loading = false;
          localStorage.setItem("clientID", this.$store.state.clientID)
          if (response.data.shift == null) {
            this.startShift = true
            this.branches = response.data.branchs
          }else{
            this.$router.push('/')
          }
        })

      }
      else {
        this.loading = false;
      }


    },

  },
};
</script>
