<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('reports') | capitalize }} . {{ $t("sales reports") | capitalize }}</strong>
          </div>
        </v-col>
      </v-row>


      <v-row align="center" justify="space-between" class="">
        <v-col cols="12" md="8">
          <v-row justify="end">
            <!-- <GenericInput type="select" :clearable="true" :lookups="services" selected_label="title"
          selected_prop="id" :value="filter.services" :multi="true" @input="filter.services = $event"
          label="services" :isLoading="false" :hideDetails="true" :cols="[12, 4, 2]"></GenericInput> -->

            <GenericInput type="select" :clearable="true" :lookups="types" selected_label="title"
              paddingY="py-2 py-lg-0" paddingX="px-4 px-lg-3" selected_prop="id" :value="filter.report_type"
              :multi="false" @input="filter.report_type = $event" label="types" :isLoading="false" :hideDetails="true"
              :cols="[12, 4, 3]"></GenericInput>

            <GenericInput type="select" :clearable="true" :lookups="payment_types" selected_label="name"
              paddingY="py-2 py-lg-0" paddingX="px-4 px-lg-3" selected_prop="id" :value="filter.payment_type"
              :multi="false" @input="filter.payment_type = $event" label="payment type" :isLoading="false"
              :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>

            <GenericInput type="date" :clearable="true" :value="filter.from_date" :multi="true" paddingY="py-2 py-lg-0"
              paddingX="px-4 px-lg-3" @input="filter.from_date = $event" label="from" :isLoading="false"
              :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>
            <GenericInput type="date" :clearable="true" :value="filter.to_date" :multi="true" paddingY="py-2 py-lg-0"
              paddingX="px-4 px-lg-3" @input="filter.to_date = $event" label="to" :isLoading="false" :hideDetails="true"
              :cols="[12, 4, 3]">
            </GenericInput>
          </v-row>
        </v-col>


        <v-col cols="auto">
          <v-row>
            <v-col cols="12" md="4" lg="2" class="d-flex">
              <v-btn :loading="isLoading" @click="getReports()" :height="40" :width="100"
                class="shadow d-block mi-start-auto white--text" color="gold">
                {{ $t("search") }}
                <v-icon size="18" right>mdi-magnify</v-icon>
              </v-btn>

              <v-btn v-if="rows.length > 0" :loading="printLoading" @click="printValueMethod()" :height="40"
                :width="100" class="shadow mx-2 white--text" color="secondary">
                {{ $t("print") }}
                <v-icon size="18" right>mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

      </v-row>



      <v-card class="mt-5 shadow overflow-hidden">
        <v-row align="center" justify="space-between">
          <v-col cols="auto" class="">
            <v-tabs v-model="tab" active-class="gold white--text" color="gold">
              <v-tab>{{ $t('reports') }}</v-tab>
              <v-tab :disabled="rows.length == 0">{{ $t('statistics') }}</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="auto" class="mx-5" v-if="tab == 1">
            <v-btn icon small @click="chartType == 1 ? chartType = 2 : chartType = 1" fab>
              <v-icon v-if="chartType == 1">mdi-chart-bar</v-icon>
              <v-icon v-if="chartType == 2">mdi-chart-bell-curve</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-col cols="12" sm="12" class="py-0">
              <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
                :searchByDateRange="false" :option="options" :viewSearch="false" :editValueMethod="edit"
                printURL="sales_report" :tableHeight="$store.state.Settings.windowSize.y - 270"
                :printValueMethod="printValueMethod" btnIcon="mdi-share" :data="rows" :header="header"
                :footerData="footerData" noDataText="please select to view report">

              </DynamicTable>
            </v-col>
          </v-tab-item>
          <v-tab-item v-if="rows.length > 0">

            <v-col cols="12" sm="12" class="pa-0">

              <SalesChart v-if="chartType == 2" :key="analysisKey" :quantities="quantities" :summary="summary"
                :summary_categories="summary_categories" />

              <SalesChartCurve v-if="chartType == 1" :key="analysisKey" :quantities="quantities" :summary="summary"
                :summary_categories="summary_categories" />
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <OptionModal :dialog="optionDialog" :backValueMethod="optionBackMethod" :row="selectedRow" />
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
// import Pagination from "@/components/Pagination";
import GenericInput from "@/components/GenericInput.vue";
import OptionModal from "@/components/modals/OptionModal.vue";
import SalesChart from "./SalesChart.vue";
import SalesChartCurve from "./SalesChartCurve.vue";
export default {
  name: "SalesReportsTable",

  data: () => ({
    isLoading: true,
    controlRoute: null,
    optionDialog: false,
    printLoading: false,
    selectedRow: null,
    options: null,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 15,
    searchText: '',
    from: null,
    to: null,
    today: null,
    date: null,
    tab: 0,
    branches: [],
    categories: [],
    customers: [],
    locations: [],
    payment_types: [],
    services: [],
    status: [],
    times: [],
    types: [],
    summary: [],
    analysisKey: 1,
    chartType: 1,
    summary_categories: [],
    quantities: [],
    filter: {
      services: [],
      report_type: 0,
      payment_type: 0,
      from_date: "",
      to_date: "",
    },
    footerData: [{
      invoice_code: null,
      branch_title: null,
      customer_name: null,
      service_title: null,
      qty: 0,
      id: null,
    }],
  }),
  components: {
    DynamicTable,
    SalesChart,
    SalesChartCurve,
    GenericInput,
    OptionModal
  },
  watch: {
    rows() {
      this.footerData[0].invoice_code = this.$i18n.t('total');
      this.footerData[0].total_without_vat = this.totalPriceWithoutVat;
      this.footerData[0].payment_type = this.rowsLength;
      this.footerData[0].total_vat = this.totalVAT;
      this.footerData[0].total_price = this.totalPrice;
      this.footerData[0].discount = this.totalDiscount;
      this.footerData[0].total_with_vat = this.totalGrandPrice;
    },

  },
  computed: {
    totalGrandPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_price);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_price);
        }
      }, 0);
    },
    totalQTY() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.qty);
      }, 0);
    },
    totalPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_price);
      }, 0);
    },
    totalDiscount() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.discount);
      }, 0);
    },
    rowsLength() {
      return this.rows.length
    },
    totalVAT() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_vat);
      }, 0);
    },
    totalPriceWithoutVat() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_without_vat);
      }, 0);
    },
  },
  mounted() {
    this.pageMainData();
    this.formatDate();
    this.payment_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('cash')
      },
      {
        id: 2,
        name: this.$i18n.t('network')
      },
    ]
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "invoice_code", type: 'text', classes: "" },
        { text: "notice invoice", key: "r_m_id", type: 'text', classes: "" },
        { text: "invoice date", key: "request_date", type: 'date', classes: "" },
        { text: "type", key: "type_title", type: 'text', classes: "" },
        // { text: "branch", key: "branch_title", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "total price", key: "total_price", type: 'float', classes: "" },
        { text: "discount", key: "discount", type: 'float', classes: "" },
        { text: "price without vat", key: "total_without_vat", type: 'float', classes: "" },
        { text: "total vat", key: "total_vat", type: 'float', classes: "" },
        { text: "total with vat", key: "total_with_vat", type: 'float', classes: "" },
        // { text: "vat", key: "vat", type: 'float', classes: "" },
        // { text: "print", key: "id", type: 'actions', classes: "" },
      ];
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
      this.today = [year, month, day].join('-');
      this.filter.from_date = firstDay.toISOString().split('T')[0];
      this.filter.to_date = [year, month, day].join('-');
    },
    optionBackMethod(status) {
      console.log(status);
      this.optionDialog = false;
      this.selectedRow = null;
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`sales_report`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          // this.services = response.data.services;
          this.categories = response.data.categorys;
          this.locations = response.data.locations;
          this.branches = response.data.branchs;
          this.types = response.data.types;
        }
      })

    },
    getReports() {
      this.isLoading = true;
      this.summary_categories = [];
      this.summary = [];
      this.quantities = [];
      this.tab = 0;
      this.rows = [];
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        report_type: this.filter.report_type,
        payment_type: this.filter.payment_type,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`sales_report`, body, false)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {

            response.data.data.forEach(row => {

              // switch (row.type) {
              //   case 3:
              //     row.rowClass = 'green10'
              //     break;
              //   case 2:
              //     row.rowClass = 'red10'
              //     break;

              //   default:
              //     row.rowClass = 'backgroundW'
              //     break;
              // }
              row.type_title = this.types[Number(row.type)].title;
              row.vat = row.item_price - (row.item_price / 1.15);
              row.price_without_vat = row.item_price - row.vat;
            })
            this.reportsRow = response.data.data;

            Object.keys(response.data.chart).forEach(date => {
              this.summary_categories.push(
                new Date(date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              );
              this.summary.push(+response.data.chart[date]['total_with_vat']);
              this.quantities.push(+response.data.chart[date]['qtys']);
            });
            this.rows = response.data.data;
            this.analysisKey = this.analysisKey + 1;
            // this.pageData.account_tree = response.data.account_tree;
          }
        });
    },
    edit(edit) {
      console.log(edit);
      this.$router.push(`${this.controlRoute}/` + edit.id)
    },


    printValueMethod() {
      this.printLoading = true;
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        report_type: this.filter.report_type,
        type: 'pdf'
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`sales_report`, body, `sales_report.pdf`)
        .then(() => { this.printLoading = false; });


      // const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/sales_report/` + row.id}?type=image"`;
      // this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getReports()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
