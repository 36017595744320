import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import InvoiceStore from "./InvoiceStore";
import router from "../router";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber 
    splashScreen: false,
    sidebarIsOpen: false,
    endpointURL: "https://theconceptsa.com/",  // Live
    // endpointURL: "https://theconcept-api.cs-testing.com/", // Testing

    apiAccess:
      "A06uj6lSJoOqn1Il5f2worBYci90VFSSneznUExPJD9kxaaM413piOEPePEKaYaSeXQKEkhwBwXefDP9moyDbJKGS1M3L2sA3uEXiHqYgZuA4Aj2MK6pappKrxkV8Y8U2Jktfh4kFVEb9o5gPyMil5U7IF2XUVcd0mLRpOLzbY0qnnYGzpkAEjDLIJY1KztR0Eou7EHH",
    //
    clientID: 'concept',
    lastUpdate:
      localStorage.getItem("lastUpdate") !== "undefined"
        ? localStorage.getItem("lastUpdate")
        : null,
        updated : new Date(),
    permissions:[],
    isAuth:false,
    shiftOpen: false,
    tableLarge: localStorage.getItem("tableLarge")
    ? localStorage.getItem("tableLarge")
    : true,
    user : null,
  },
  mutations: {
    UPDATE_CLIENT(state, id) {
      state.clientID = id;
      axios.defaults.headers.common["clientID"] = id;
    },
    LAST_UPDATE(state, date) {
      state.lastUpdate = new Date(date);
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem(
        Vue.prototype.$global.CRYPT("cloud", "user"),
        Vue.prototype.$global.CRYPT("cloud", JSON.stringify(user))
      );
    },
  },

  actions: {
    checkAppVersion({ state }) {
      let localStorageVersion = localStorage.getItem("app_version");
      if (state.app_version != localStorageVersion) {
        state.isAuth = false;
        router.push("/login");
        axios.interceptors.response.use((resp) => {
          if (resp.config.method == "get") {
            localStorage.setItem("app_version", state.app_version);
            window.location.reload();
          }
          return Promise.resolve(resp);
        });
      }
    },
    checkAuth({ state }) {

      state.isAuth = localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "access-token")) ? true : false;
      if (!state.isAuth) {
        router.push("/login");
      }else{
        state.accessToken = Vue.prototype.$global.DECRYPT("cloud", localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "access-token")));
        axios.defaults.headers.common["Authorization"] = "Bearer " + state.accessToken;
        state.permissions = JSON.parse(Vue.prototype.$global.DECRYPT("cloud",localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "permissions"))));
        state.user = JSON.parse(Vue.prototype.$global.DECRYPT("cloud",localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "user"))));
      }
    },
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    setLastUpdate({ commit }, date) {
      commit("LAST_UPDATE", date);
    },
  },

  modules: { Settings, InvoiceStore },
});
