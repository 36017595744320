import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../router";
import store from "../store";
import i18n from "./i18n";
//////////////////


var accessToken = localStorage.getItem("access-token");
const branch = localStorage.getItem("currentbranch");


///////// Axios Defaults Header /////////

axios.defaults.baseURL = store.state.endpointURL + 'api/v1/' + store.state.Settings.language + "/";
axios.defaults.headers.common["access-token"] = store.state.apiAccess;
axios.defaults.headers.common["clientID"] = store.state.clientID;
axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
axios.defaults.headers.common["currentbranch"] = branch;
// axios.defaults.timeout = 180000; // 3min

export default {
  install(Vue) {
    // define a global property
    Vue.PLUGIN_VERSION = "0.0.2";
    Vue.prototype.$api = {
      serverUrl :  store.state.endpointURL,
      //  GET ------------------------------------------------------------
      async GET_METHOD(RESOURCE_NAME) {
        if (navigator.onLine == true) {
        try {
          const response = await axios.get(RESOURCE_NAME);
          if(response.data.check == false){
            this.ShowAlert("error", i18n.t("Error") , response.data.msg);
          }
          return response.data;
        } catch (error) {
          console.log('error ==>' , error.response.status);
          this.SetError(error.response.status, error);
          return false
        }}
        else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
       },
      //  Download File ------------------------------------------------------------
      async DOWNLOAD_METHOD(RESOURCE_NAME) {
        window.open(`${store.state.endpointURL + 'api/v1/' + store.state.Settings.language + "/"}${RESOURCE_NAME}&clientID=${store.state.clientID}`  );
       },
      //  POST ------------------------------------------------------------
       async POST_METHOD(RESOURCE_NAME ,NEW_DATA ,TOAST = false, TOAST_MESSAGE = " ") {
        if (navigator.onLine == true) {
        try {
          const response = await axios.post(RESOURCE_NAME, NEW_DATA);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          if (!TOAST && response.data.check == true && response.data.msg) {
            this.ShowAlert("success", " ", response.data.msg);
          }
          if(response.data.check == false){
            this.ShowAlert("error", "Error", response.data.msg);
          }
          return response.data;
        } catch (error) {
          if (error.response) {
            this.SetError(error.response.status, error);
            console.log('error ==>' ,error.response.status,error.response.data,error.response.headers);
            return false;
          } else if (error.request) {
            console.log('error ==>' ,error.request);
            this.SetError(null, error.request);
            return false;
          } else {
            this.SetError(null, error.message);
            console.log('error ==>' , error.message);
            return false;
          }
        }
      } else {
        this.ShowModal("error", null, i18n.t("No Internet Connection"));
        return false;
      }
       },
      //  PUT ------------------------------------------------------------
       async PUT_METHOD(RESOURCE_NAME ,ID ,NEW_DATA ,TOAST = false,TOAST_MESSAGE = " ") {
        try {
          const response = await axios.put(RESOURCE_NAME + "/" + ID, NEW_DATA);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.SetError(error.response.status, error);
        }
       },
      //  DELETE ------------------------------------------------------------
       async DELETE_METHOD(RESOURCE_NAME, ID, TOAST = false, TOAST_MESSAGE = " ") {
        try {
          const response = await axios.delete(RESOURCE_NAME + "/" + ID);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.SetError(error.response.status, error);
        }
       },
   //   LOGIN ------------------------------------------------------------
       async LOGIN_AUTH(LOGIN_DATA, TOAST = true, TOAST_MESSAGE = i18n.t("Welcome Back")) {
        try {
          const response = await axios.post("login2", LOGIN_DATA);
          if(response.data.check == false){
            this.ShowAlert("error", response.data.msg , "");
          }else{
            if (TOAST) {
              this.ShowAlert("success",  i18n.t("Logged in successfully") , TOAST_MESSAGE);
            }
            this.SET_AUTH(response.data.data);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.SetError(error.response.status, error);
        }
       },
     
       ///////// Set Auth Data /////////
       SET_AUTH(AUTH_DATA) {
        localStorage.setItem(
          Vue.prototype.$global.CRYPT("cloud", "access-token"),
          Vue.prototype.$global.CRYPT("cloud", AUTH_DATA.api_token)
        );
        axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_DATA.api_token;
       
          // router.push("/");
          store.state.isAuth= true;
          store.dispatch("setUser", AUTH_DATA);
    
        return AUTH_DATA;
       },
       
       ///////// Check Internet Connection /////////
        CHECK_INTERNET(){
          return navigator.onLine
       },
       ///////// Logout /////////
       LOGOUT(reload = true) {
        store.state.isAuth= false;
        store.state.permissions= [];
        store.state.InvoiceStore.bill= [];
        var lang = localStorage.getItem("language");
        localStorage.clear();
        localStorage.setItem("language", lang ? lang : "ar");
        axios.defaults.headers.common["Authorization"] = null;
        axios.defaults.headers.common["currentbranch"] = null;
        axios.defaults.headers.common["clientID"] = null;
        if (reload == true) {
          router.push("/login");
          window.location.reload(true);
        } else {
          router.push("/login");
        }
       },
     
       ///////// API Errors Action /////////
       SetError(STATUS, ERROR) {
        switch (STATUS) {
          case 401:
          // not auth
          this.ShowModal("error", "Request error", ERROR);
          // this.LOGOUT(false)
           break;
          case 404:
           // not found
           router.push("/notfound");
           break;
          case 403:
           // not Auth
          //  this.LOGOUT(true)
          this.ShowModal("error", "Request error", ERROR);
           break;
          case 500:
           // server error
           this.ShowModal("error",i18n.t("error happend"),i18n.t("Try to refresh the page or feel free to contact us if the problem persists"));
           break;
        
          default:
            this.ShowModal("error", "Request error", ERROR);
            break;
        }
       },
     
       ///////// Alert Toast /////////
       ShowAlert(TYPE, TITLE, MSG) {
         Swal.fire({
           icon: TYPE,
           title: TITLE,
           text: MSG,
           toast: true,
           timer: 3000,
           timerProgressBar: true,
           position: store.state.Settings.translation.rtl == true ? "top-start" : "top-end",
           showConfirmButton: false,
           showCloseButton: true,
           allowOutsideClick: false,
           didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
         });
       },
       ///////// Modal /////////
      ShowModal(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: false,
          timer: 3000,
          showConfirmButton: false,
          showCloseButton: false,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
       //  Print
       PrintInvoice(URL,ID = 0) {
      // var printInvoice = window.open('_blank', 'فاتورة TheConcept - POS', 'width=50,height=50');
      // printInvoice.document.write('<html><head><style type="text/css"> img{opacity : 0;}@media print {img{opacity: 1;}} </style></head>');
      // printInvoice.document.write(`<img ${src}  onload="window.print(); window.close()" />`);
      // printInvoice.document.write('</body></html>');
        let printInvoice = document.createElement('iframe');
        printInvoice.name = "printInvoice_" + ID;
        printInvoice.style.position = "absolute";
        printInvoice.style.top = "-1000000px";
        document.body.appendChild(printInvoice);
        let invoice = printInvoice.contentWindow ? printInvoice.contentWindow : printInvoice.contentDocument.document ? printInvoice.contentDocument.document : printInvoice.contentDocument;
        invoice.document.open();
        invoice.document.write(`<html lang="ar"><head><title> فاتورة ذا كونسبت رقم ${ID}</title>`);
        invoice.document.write('</head><body>');
        invoice.document.write(`<img ${URL} onload="window.print(); window.close()"  />`);
        invoice.document.write('</body></html>');
        return false;
       },

       async DOWNLOAD_METHOD_BODY(RESOURCE_NAME,BODY, FILE_NAME = "file.jpg") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post(RESOURCE_NAME, BODY, {
              responseType: "blob",
            });
            console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", FILE_NAME);
            document.body.appendChild(link);
            link.click();
            return response.data;
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },

    };
  },
};
