<template>

  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="bg-white" block v-bind="attrs" v-on="on" large depressed rounded-md>
        <img class="mx-1" src="../../assets/img/svg/cashier.svg" alt="Qarat-POS" />
        <span class="d-md-block d-none" v-if="!sellar">{{ $t('changeSellar')}}</span>
        <span class="d-md-block d-none" v-if="sellar">{{ sellar.title }}</span>
      </v-btn>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6">{{showPin ? $t('please write pin'): $t('changeSellar') }}</v-card-title>
      <v-divider></v-divider>
      <v-form class="pa-5">
        <v-col cols="12" sm="12" v-if="!showPin">
          <v-btn-toggle v-model="toggle_sellar" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
            <v-row>
              <v-col cols="12" v-for="(agent, index) in sales_agents" :key="index">
                <v-btn v-on:click="selectAgent(agent ,index)" :value="agent" class="rounded ma-1 text-right"
                  color="gray lighten-5" elevation="0" style="width: 100%; text-align: start">
                  <v-icon color="blue accent-4" v-if="index == 0 ||sellar == agent" :disabled="sellar != agent" left>{{ mdiCheckCircle }}
                  </v-icon>
                  <v-icon color="error" v-if="index > 0 && sellar != agent" left>mdi-lock-outline</v-icon>
                  {{ agent.title }}
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="sales_agents.length == 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('No data available') }}</v-alert>
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="12" class="d-flex justify-center" dir="ltr" v-if="showPin">
          <v-otp-input ref="otpInput" v-model="otp" input-classes="otp-input" separator=" " :key="otpKey" :num-inputs="4" :should-auto-focus="true"
            :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" />
        </v-col>
        <v-col cols="12" class="py-4">
          <v-btn color="gold" v-if="!showPin" large @click="returnData" class="shadow white--text"
            style="width: 100%; text-align: start">{{
            $t('choose') }}</v-btn>
          <v-btn color="error" text :loading="loading" v-if="showPin" large @click="showPin=false" style="width: 100%; text-align: start">{{
          $t('back') }}</v-btn>

        </v-col>
      </v-form>
    </v-card>
  </v-dialog>

</template>



<style scoped lang="scss">

</style>



<script>
import { mdiCheckCircle } from "@mdi/js";
export default {
  name: "SalesAgentModal",
  props: {
    backValueMethod: { type: Function },
    sales_agents: { default: [] },
    sellar: { default: {} },
  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyS") {
        this.dialog = true;
      }
      if (e.code == 'Escape') {
        this.dialog = false;
      }
    });
  },
  watch: {
    dialog() {
      if (this.dialog == false){
        this.showPin = false
      }
  }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      toggle_sellar: 1,
      showPin: false,
      otp: null,
      otpKey: 1,
      lockedAgent: Object,
      mdiCheckCircle: mdiCheckCircle,
    };
  },
  methods: {
    returnData() {
      this.backValueMethod(this.sellar);
      this.dialog = false;
      this.showPin = false
    },
    selectAgent(agent, index) {
      if (index == 0) {
        this.backValueMethod(agent);
        this.dialog = false
      } else {
        this.lockedAgent = agent;
        this.showPin = true;
      }
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleOnComplete(value) {
      this.loading = true;
      const requestBody = {
        sales_agent_id: this.lockedAgent.id,
        sales_agent_pin: Number(value),
      }
      this.$api.POST_METHOD('check_sales_agent_pin', requestBody).then((response) => {
        this.loading = false;
        console.log('response response',response);
        if (response.check === true) {
          this.backValueMethod(this.lockedAgent);
          this.otpKey++;
          this.dialog = false
        }else{
          this.otp = null;
          this.otpKey++;
          this.loading = false;
        }
      })
    },
  },
};
</script>
