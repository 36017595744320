<template>
  <v-card relative>
    <v-btn absolute class="mt-2 mx-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
      color="grey lighten-1" text to="/">
      <v-icon color="grey lighten-1">mdi-close</v-icon>
    </v-btn>
    <v-card-title class="text-h6">{{ $t('endShift') }}</v-card-title>
    <v-divider></v-divider>
    <v-form class="pa-5" ref="form" v-model="form" lazy-validation>
      <v-col cols="12" class="py-0">
        <h5 class="mb-3">{{ $t('finalCash') }}</h5>
        <v-text-field hide-details="auto" class="my-2 rounded grey lighten-5" filled v-model="cash" :value="0" dense
          rounded type="number"></v-text-field>
      </v-col>
   
      <v-col cols="12" class="py-4">
        <v-btn color="gold" large @click="closeShift" :loading="loading" :disabled="loading" class="shadow white--text"
          style="width: 100%">{{ $t('endShift')}}</v-btn>
      </v-col>
    </v-form>
  </v-card>
</template>



<style scoped lang="scss">

</style>



<script>

export default {
  name: "endShift",
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      form: [],
      dialog: false,
      loading: false,
      cash: null,
    };
  },
  mounted() {
    document.title = this.$i18n.t("The Concept - Close shift");
  },
  methods: {
    closeShift() {
      this.loading = true;
      // this.$refs.form.validate()
      const requstBody = {
        final_cash: Number(+this.cash) || 0
      }
      this.$api.POST_METHOD("end_shift", requstBody).then(() => {
        // console.log(response);
        this.dialog = false
        this.loading = false;
        this.$api.LOGOUT(true);
      })
    },

  },
};
</script>
