<template>
  <v-card class="shadow rounded-lg justify-center my-2 py-5">
    <v-row justify-md="space-between" justify="center" align="center">
      <v-col cols="auto" md="auto" class="px-8 pb-5 d-flex align-center" v-if="viewTitle && controlRoute">
        <!-- <h4>{{ $t(tableTitle) }}</h4> -->
        <v-btn :to="controlRoute" class="shadow" color="success">
          <v-icon dark left>mdi-plus</v-icon>
          {{ $t(createBtnLabel) }}
        </v-btn>
      </v-col>
      <v-col cols="12" :md="viewTitle && controlRoute ? 8 : 12" class="px-8 pb-5">
        <v-row align="center" justify-md="end" justify="center">
          <slot name="inputs"></slot>
          <GenericInput type="date" :maxDate="today" v-if="searchByDate" :placeholder="'date'" :value="date"
            @input="date = $event" label="date" :required="false" :hide-details="true" :isLoading="isLoading"
            :cols="[12, 4, 3]">
          </GenericInput>

          <GenericInput type="date" :minDate="fromDate" :maxDate="toDate || today" v-if="searchByDateRange"
            :placeholder="'date'" clearable :value="fromDate" @input="fromDate = $event" label="from" :required="false"
            :hide-details="true" :isLoading="isLoading" :cols="[12, 4, 3]">
          </GenericInput>

          <GenericInput type="date" :minDate="fromDate" :maxDate="today" v-if="searchByDateRange" :placeholder="'to'"
            :value="toDate" @input="toDate = $event" clearable label="to" :required="false" :hide-details="true"
            :isLoading="isLoading" :cols="[12, 4, 3]">
          </GenericInput>

          <v-col cols="12" md="4" lg="3" v-if="viewSearch">
            <v-text-field v-model="searchText" style="border: 1px solid #e6e6e6" solo error
              @keydown.enter="(e) => searchMethod(e.target.value, date, fromDate, toDate)"
              @click:clear="(e) => searchMethod(e.target.value, date, fromDate, toDate)" :loading="isLoading"
              @click:append="setSearch()" append-icon="mdi-magnify" class="rounded-lg" :placeholder="$i18n.t('Search')"
              single-line clearable hide-details dense :height="32"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" lg="3" v-if="viewSearch">
            <v-btn block :loading="isLoading" @click="setSearch()" :height="40" :width="100" class="shadow white--text"
              color="gold">
              {{ $t("search") }}
              <v-icon size="18" right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-col cols="12" sm="12">
      <v-simple-table :fixed-header="true" :height="data.length > 0 ? tableHeight : 'auto'" :dense="$store.state.tableLarge" v-if="!isLoading">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(header, index) in header" class="text-center" :key="index">{{ $t(header.text) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in data" :key="index" :class="row.rowClass">
              <td class="text-center" v-for="(header, headerIndex) in header" :class="header.classes"
                :key="headerIndex">
                <!-- text -->
                <span v-if="header.type == 'text'">{{ row[header.key] || '-' }}</span>
                <!-- float -->
                <span v-else-if="header.type == 'float'">{{ row[header.key] | float }}</span>
                <!-- price -->
                <span v-else-if="header.type == 'price'">{{ row[header.key] | float }}
                  {{ $t('ryal') }}</span>
                <!-- weight -->
                <span v-else-if="header.type == 'weight'">{{ $global.DecimalFormatter(row[header.key].toFixed(2)) || '-'
                }}
                  {{ $t('gram') }}</span>
                <!-- pieces  -->
                <span v-else-if="header.type == 'pieces'">{{ row[header.key] || '-' }} {{ $t('pieces') }}</span>
                <!-- date -->
                <span v-else-if="header.type == 'date'"> {{
                    new Date(row[header.key]).toLocaleDateString(
                      `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }
                    )
                    || '-'
                }}</span>
                <!-- print -->
                <span v-else-if="header.type == 'print'">
                  <v-btn color="transparent" elevation="0" :disabled="printLoading" :loading="printLoading"
                    v-on:click="getInvoice(row[header.key])">
                    <img src="../assets/img/svg/pdf.svg" alt="TheConcept-POS" />
                  </v-btn>
                </span>
                <!--  button -->
                <span v-else-if="header.type == 'button'">
                  <v-btn @click="buttonValueMethod(row)" small :icon="btnIcon ? true : false" depressed
                    :class="header.buttonClass + ' ' + row['button_color']">
                    {{ $t(row[header.key]) }}
                    <v-icon v-if="btnIcon">{{ btnIcon }}</v-icon>
                  </v-btn>
                </span>
                <!-- actions -->
                <span v-else-if="header.type == 'actions' && option">
                  <div class="d-flex align-center justify-center">
                    <!-- print -->
                    <span v-if="option.print">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" v-if="option.print" @click="printValueMethod(row)" icon>
                            <v-icon color="info">mdi-printer</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('print') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.edit && isLoading == false">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                            @click="editValueMethod(row)" src="@/assets/img/svg/pen.svg" alt="concept" />
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.delete && isLoading == false">
                      <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click="cancellation(row)"
                            src="@/assets/img/svg/delete_icon.svg" color="red4">mdi-close</v-icon>
                        </template>
                        <span>{{ $t('cancellation') }}</span>
                      </v-tooltip>
                    </span>


                  </div>
                </span>

                <span v-else-if="header.type == 'slot'" class="d-flex justify-center">
                  <slot name="td" :row="row"></slot>
                </span>
                <span v-else>-</span>
              </td>

            </tr>
            <!-- <tr v-if="data.length == 0 && !isLoading" class="white">
              <th colspan="20" class="my-5 py-5">
                <v-alert class="d-block ma-auto" :dense="$store.state.tableLarge" text type="info">{{
                    $t('noInvoicesAdded')
                }}</v-alert>
              </th>
            </tr> -->
          </tbody>
          <tfoot v-if="footerData && data.length > 0">
            <tr v-for="(row, index) in footerData" :key="index">
              <td class="text-center light  sticky_header" v-for="(header, headerIndex) in header"
                :class="header.classes" :key="headerIndex">
                <!-- text -->
                <span v-if="header.type == 'text'" class="font-weight-black ">{{ row[header.key] || '-' }}</span>
                <!-- float -->
                <span v-else-if="header.type == 'float'" class="font-weight-black">{{
                    $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                    || '-'
                }}</span>
                <!-- price -->
                <span v-else-if="header.type == 'price'" class="font-weight-black">{{
                    $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                    || '-'
                }}</span>




                <span v-else>-</span>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <!-- No data -->
      <div v-if="data.length == 0 && !isLoading" class="my-3">
        <!-- <img src="@/assets/img/svg_icons/no_data.svg" height="150" class="d-block my-3 mx-auto" alt="qawaem"> -->
        <div class="text-h6 text-center  gray8--text">{{ $t(noDataText) | capitalize }}</div>
      </div>
      <v-row justify="center" v-if="isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
          </v-progress-circular>
          <p class="text-center">{{ $t("Loading data") }}</p>
        </v-col>
      </v-row>
      <!-- <v-skeleton-loader class="mx-auto my-5 py-5" type="heading" v-if="isLoading"></v-skeleton-loader> -->
    </v-col>
  </v-card>
</template>





<script>
import GenericInput from "./GenericInput.vue";

export default {
  name: "DynamicTable",
  computed: {
    searchValid() {
      if (this.searchText || this.date || this.fromDate || this.toDate) {
        return true
      }
      else {
        return false
      }
    }
  },
  components: {
    GenericInput
  },
  watch: {
    fromDate() {
      this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
    },
    toDate() {
      this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
    },
    date() {
      this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
    }
  },
  props: {
    tableHeight: {
      default() {
        return this.$store.state.Settings.windowSize.y - 300
      },
    },
    searchMethod: { type: Function },
    buttonValueMethod: { type: Function },
    btnIcon: { default: '' },
    isLoading: { default: false },
    changeTableSize: { default: true },
    viewSearch: { default: true },
    searchByDate: { default: false },
    searchByDateRange: { default: false },
    viewTitle: { default: true },
    data: { default: [] },
    header: { default: [] },
    printURL: { default: null },
    tableTitle: { default: "invoiceList" },
    controlRoute: { default: null },
    createBtnLabel: { default: 'create invoice' },
    option: { default: null },
    editValueMethod: { type: Function },
    cancellation: { type: Function },
    printValueMethod: { type: Function },
    footerData: { default: null },
   noDataText: { default: "you haven't entered anything yet" },
  },
  data: () => ({
    tableLarge: false,
    printLoading: false,
    searchText: '',
    fromDate: null,
    toDate: null,
    date: null,
    today: null,
  }),
  mounted() {
    // this.formatDate();
  },
  methods: {
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.today = [year, month, day].join('-');
      this.date = [year, month, day].join('-');
    },
    setSearch() {
      this.searchText = this.searchText || null,
        this.fromDate = this.fromDate || null,
        this.toDate = this.toDate || null,
        this.toDate = this.toDate || null,
        this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
    },
    changeTableSizeMethod() {
      localStorage.setItem('tableLarge', this.$store.state.tableLarge || false)
      this.$store.state.tableLarge = !this.$store.state.tableLarge;
    },
    async getInvoice(id) {
      this.printLoading = true;
      // const src = `src="${this.$api.serverUrl}${this.printURL}/${id}?type=image"`;
      // await this.$api.PrintInvoice(src, id);

      const src = `${this.printURL}/${id}?type=pdf`;
      this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('booking')}_${id}.pdf`).then(() => {
        this.pageData.printLoading = false;
      })


      this.printLoading = false;
    },
  },
};
</script>

<style  lang="scss">
thead {
  tr {
    background-color: #e8e8ee !important;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(odd) {
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }
  }

  &:nth-of-type(even) {
    background-color: #e8e8ee;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }

  // &:last-of-type {
  //   background-color: #E8E8EE;
  //   &:hover {
  //     background-color: #E8E8EE !important;
  //   }
  // }
}

td,
th {
  border-bottom: 0 !important;
}
</style>

