<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('reports') | capitalize }} . {{ $t("final report") | capitalize }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>


        <v-row align="center" justify="end" class="py-3 py-lg-0">
          <GenericInput type="select" :clearable="true" :lookups="services" selected_label="title" selected_prop="id"
            :value="filter.services" :multi="true" @input="filter.services = $event" label="services" :isLoading="false"
            :hideDetails="true" :cols="[12, 4, 2]"></GenericInput>

          <GenericInput type="select" :clearable="true" :lookups="types" selected_label="title" selected_prop="id"
            :value="filter.report_type" :multi="false" @input="filter.report_type = $event" label="types"
            :isLoading="false" :hideDetails="true" :cols="[12, 4, 2]"></GenericInput>

          <GenericInput type="select" :clearable="true" :lookups="payment_types" selected_label="name"
            selected_prop="id" :value="filter.payment_type" :multi="false" @input="filter.payment_type = $event"
            label="payment type" :isLoading="false" :hideDetails="true" :cols="[12, 4, 2]"></GenericInput>

          <GenericInput type="date" :clearable="true" :value="filter.from_date" :multi="true"
            @input="filter.from_date = $event" label="from" :isLoading="false" :hideDetails="true" :cols="[12, 4, 2]">
          </GenericInput>
          <GenericInput type="date" :clearable="true" :value="filter.to_date" :multi="true"
            @input="filter.to_date = $event" label="to" :isLoading="false" :hideDetails="true" :cols="[12, 4, 2]">
          </GenericInput>


          <v-col cols="12" md="4" lg="2" class="d-flex">
            <v-btn :loading="isLoading" @click="getReports()" :height="40" :width="100"
              class="shadow d-block mi-start-auto white--text" color="gold">
              {{ $t("search") }}
              <v-icon size="18" right>mdi-magnify</v-icon>
            </v-btn>

            <v-btn v-if="rows.length > 0" :loading="printLoading" @click="printValueMethod()" :height="40" :width="100"
              class="shadow mx-2 white--text" color="secondary">
              {{ $t("print") }}
              <v-icon size="18" right>mdi-printer</v-icon>
            </v-btn>
          </v-col>

        </v-row>







        <v-col cols="12" sm="12" class="py-0">
          <v-card class="mt-5 shadow overflow-hidden">
            <v-row align="center" justify="space-between">
              <v-col cols="auto" class="">
                <v-tabs v-model="tab" active-class="gold white--text" color="gold">
                  <v-tab>{{ $t('reports') }}</v-tab>
                  <v-tab :disabled="rows.length == 0">{{ $t('statistics') }}</v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="auto" class="mx-5" v-if="tab == 1">
                <v-btn icon small @click="chartType == 1 ? chartType = 2 : chartType = 1" fab>
                  <v-icon v-if="chartType == 1">mdi-chart-bar</v-icon>
                  <v-icon v-if="chartType == 2">mdi-chart-bell-curve</v-icon>
                </v-btn>
              </v-col>
            </v-row>
  
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-col cols="12" sm="12" class="py-0">
                  <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
                    :searchByDateRange="false" :option="options" :viewSearch="false" :editValueMethod="edit"
                    printURL="full_report" :tableHeight="$store.state.Settings.windowSize.y - 270"
                    :printValueMethod="printValueMethod" btnIcon="mdi-share" :data="rows" :header="header"
                    :footerData="footerData" noDataText="please select to view report">
                  </DynamicTable>
                </v-col>
              </v-tab-item>
              <v-tab-item v-if="rows.length > 0">
                <v-col cols="12" sm="12" class="pa-0">
                  <FinalChart v-if="chartType == 2" :key="analysisKey" :quantities="quantities" :summary="summary" :summary_categories="summary_categories" />

                  <FinalChartCurve v-if="chartType == 1" :key="analysisKey" :quantities="quantities" :summary="summary"
                  :summary_categories="summary_categories" />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="12" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col> -->
      </v-row>

      <OptionModal :dialog="optionDialog" :backValueMethod="optionBackMethod" :row="selectedRow" />
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
// import Pagination from "@/components/Pagination";
import GenericInput from "@/components/GenericInput.vue";
import OptionModal from "@/components/modals/OptionModal.vue";
import FinalChart from "./FinalChart.vue";
import FinalChartCurve from "./FinalChartCurve.vue";
export default {
  name: "FinalReportsTable",

  data: () => ({
    isLoading: true,
    controlRoute: null,
    optionDialog: false,
    printLoading: false,
    selectedRow: null,
    options: null,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 15,
    searchText: '',
    from: null,
    to: null,
    today: null,
    date: null,
    branches: [],
    categories: [],
    customers: [],
    locations: [],
    payment_types: [],
    services: [],
    status: [],
    times: [],
    types: [],
    summary: [],
    quantities: [],
    tab: 0,
    chartType: 1,
    analysisKey: 1,
    summary_categories: [],
    filter: {
      services: [],
      report_type: 0,
      payment_type: 0,
      from_date: "",
      to_date: "",
    },
    footerData: [{
      invoice_code: null,
      branch_title: null,
      customer_name: null,
      service_title: null,
      qty: 0,
      id: null,
    }],
  }),
  components: {
    DynamicTable,
    FinalChart,
    FinalChartCurve,
    GenericInput,
    OptionModal
  },
  watch: {
    rows() {
      this.footerData[0].invoice_code = this.$i18n.t('total');
      this.footerData[0].qty = this.totalQTY;
      this.footerData[0].vat = this.totalVat;
      this.footerData[0].price_without_vat = this.totalPriceWithoutVat;
      this.footerData[0].item_price = this.totalGrandPrice;
    },
  },
  computed: {
    totalGrandPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.item_price);
          case 2:
            return Number(+oldValue) - Number(+newValue.item_price);
        }
      }, 0);
    },
    totalQTY() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.qty);
      }, 0);
    },
    totalVat() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.vat);
      }, 0);
    },
    totalPriceWithoutVat() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.price_without_vat);
      }, 0);
    },
  },
  mounted() {
    this.pageMainData();
    this.formatDate();
    this.payment_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('cash')
      },
      {
        id: 2,
        name: this.$i18n.t('network')
      },
    ]
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "invoice_code", type: 'text', classes: "" },
        { text: "notice invoice", key: "r_m_id", type: 'text', classes: "" },
        { text: "invoice date", key: "request_date", type: 'date', classes: "" },
        { text: "type", key: "type_title", type: 'text', classes: "" },
        { text: "branch", key: "branch_title", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "service", key: "service_title", type: 'text', classes: "" },
        { text: "count", key: "qty", type: 'text', classes: "" },
        { text: "price without vat", key: "price_without_vat", type: 'float', classes: "" },
        { text: "vat", key: "vat", type: 'float', classes: "" },
        { text: "total with vat", key: "item_price", type: 'float', classes: "" },
        // { text: "print", key: "id", type: 'actions', classes: "" },
      ];
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
      this.today = [year, month, day].join('-');
      this.filter.from_date = firstDay.toISOString().split('T')[0];
      this.filter.to_date = [year, month, day].join('-');
    },
    optionBackMethod(status) {
      console.log(status);
      this.optionDialog = false;
      this.selectedRow = null;
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`full_report`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.services = response.data.services;
          this.categories = response.data.categorys;
          this.locations = response.data.locations;
          this.branches = response.data.branchs;
          this.types = response.data.types;
        }
      })

    },
    getReports() {
      this.isLoading = true;
      this.summary_categories = [];
      this.summary = [];
      this.quantities = [];
      this.tab = 0;
      this.rows = [];
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        services: this.filter.services,
        report_type: this.filter.report_type,
        payment_type: this.filter.payment_type,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`full_report`, body, false)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {

            response.data.data.forEach(row => {

              // switch (row.type) {
              //   case 3:
              //     row.rowClass = 'green10'
              //     break;
              //   case 2:
              //     row.rowClass = 'red10'
              //     break;

              //   default:
              //     row.rowClass = 'backgroundW'
              //     break;
              // }
              row.type_title = this.types[Number(row.type)].title;
              row.vat = row.item_price - (row.item_price / 1.15);
              row.price_without_vat = row.item_price - row.vat;
            })
            this.reportsRow = response.data.data;
            Object.keys(response.data.chart).forEach(service => {
              this.summary_categories.push(service);
              this.summary.push(+response.data.chart[service]['total_with_vat']);
              this.quantities.push(+response.data.chart[service]['qtys']);
            });
            this.rows = response.data.data;
            this.analysisKey = this.analysisKey + 1;
            this.rows = response.data.data;
            // this.pageData.account_tree = response.data.account_tree;
          }
        });
    },
    edit(edit) {
      console.log(edit);
      this.$router.push(`${this.controlRoute}/` + edit.id)
    },


    printValueMethod() {
      this.printLoading = true;
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        services: this.filter.services,
        report_type: this.filter.report_type,
        type: 'pdf'
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`full_report`, body, `full_report.pdf`)
        .then(() => { this.printLoading = false; });


      // const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/full_report/` + row.id}?type=image"`;
      // this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getReports()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
