<template>
   <small :key="key" style="">
      <div style="position :fixed; bottom: 0; right : 0; background: rgba(0,0,0,0.1);" :class="speedColor + '--text'" class="d-flex pa-1 px-2">
         <v-icon id="wifiIcon" size="14" :color="speedColor" v-if="speed > 4">mdi-signal-cellular-3</v-icon>
         <v-icon id="wifiIcon" size="14" :color="speedColor" v-if="speed < 4 && speed > 2 ">mdi-signal-cellular-2</v-icon>
         <v-icon id="wifiIcon" size="14" :color="speedColor" v-if="speed < 2 ">mdi-signal-cellular-1</v-icon>
         <div id="result" class="px-2" :class="speedColor +'--text'">  {{ $t('Mbps') }} {{ speed }}

            <vue-identify-network @network-type="handleNetworkIdentified" @network-speed="handleNetworkSpeed">
               <template #unknown>
                  {{$t('Unable to identify your network')}}
               </template>
            </vue-identify-network>

         </div>
      </div>
   </small>
</template>


<script>

export default {
   name: "SpeedTest",
   components: {},
   mounted() {
      setInterval(() => {
         // InitiateSpeedDetection();
         this.key = this.key + 1
      }, 7000);

      // InitiateSpeedDetection()

      // var imageAddr = "https://new.theconcepterp.com/img/speedTestImg.ea5efc10.jpg";
      // var downloadSize = 2101546;

      // function ShowProgressMessage(msg, color) {
      //    document.getElementById('result').innerHTML = msg;
      //    document.getElementById('wifiIcon').style.color = color;
      //    document.getElementById('result').style.color = color;
      // }

      // function InitiateSpeedDetection() {
      //    ShowProgressMessage(" ", "#FDD4D4");
      //    window.setTimeout(MeasureConnectionSpeed, 0);
      // }

      // function MeasureConnectionSpeed() {
      //    var startTime, endTime;
      //    var download = new Image();
      //    download.onload = function () {
      //       endTime = (new Date()).getTime();
      //       showResults();
      //    }
      //    download.onerror = function () {
      //       ShowProgressMessage("");
      //    }
      //    startTime = (new Date()).getTime();
      //    var cacheBuster = "?nnn=" + startTime;
      //    download.src = imageAddr + cacheBuster;
      //    function showResults() {
      //       var duration = (endTime - startTime) / 1000;
      //       var bitsLoaded = downloadSize * 8;
      //       var speedBps = (bitsLoaded / duration).toFixed(2);
      //       var speedKbps = (speedBps / 1024).toFixed(2);
      //       var speedMbps = (speedKbps / 1024).toFixed(2);

      //       if (speedMbps > 3) {
      //          ShowProgressMessage(speedMbps + "Mbps", "#1eb340");
      //       } else if (speedMbps <= 1) {
      //          ShowProgressMessage(speedMbps + " Mbps", "#ffd355");
      //       } else if (speedKbps > 1) {
      //          ShowProgressMessage(speedKbps + " kbps", "#ff4141");
      //       } else {
      //          ShowProgressMessage('-', "#ff4141");
      //       }
      //    }
      // }



   },
   data() {
      return {
         loading: false,
         key: 1,
         speed: 0,
         speedColor: "error",
         connectionType: null,
      }
   },
   methods: {
      handleNetworkIdentified(type) {
         this.connectionType = type
      },
      handleNetworkSpeed(speed) {
         this.speed = speed
         if (Number(speed) > 4) {
            this.speedColor = "success"
         } else if (Number(speed) < 4 && Number(speed) > 2) {
            this.speedColor = "warning"
         } else {
            this.speedColor = "error"
         }
         // console.log(Number(speed));
      },
   },

}
</script>
<style scoped>
</style>