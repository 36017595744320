<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('sales') | capitalize }} . {{ $t("invoices") | capitalize }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
            :searchByDateRange="false" createBtnLabel="create invoice" :option="options" :editValueMethod="edit"
            printURL="print_request" :printValueMethod="printValueMethod" btnIcon="mdi-share"
            :buttonValueMethod="showDialog" :cancellation="cancellation" :data="rows" :header="header">


            <template v-slot:inputs>
              <GenericInput type="select" :clearable="true" :lookups="branches" selected_label="title"
                selected_prop="id" :value="filter.branch" @input="filter.branch = $event" label="branches"
                :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>

              <GenericInput type="select" :clearable="true" :lookups="payment_types" selected_label="payment_type"
                selected_prop="payment_type_id" :value="filter.payment_method" @input="filter.payment_method = $event"
                label="payment type" :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>

              <!-- <GenericInput type="select" :clearable="true" :lookups="status" selected_label="status_title"
                selected_prop="id" :value="filter.status" @input="filter.status = $event" label="status"
                :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput> -->

            </template>


          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>

      <OptionModal :dialog="optionDialog" :backValueMethod="optionBackMethod" :row="selectedRow" />
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import GenericInput from "@/components/GenericInput.vue";
import OptionModal from "@/components/modals/OptionModal.vue";
export default {
  name: "SalesInvoiceTable",

  data: () => ({
    isLoading: true,
    controlRoute: '/invoice-control',
    optionDialog: false,
    selectedRow: null,
    options: null,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 15,
    searchText: '',
    from: null,
    to: null,
    date: null,
    branches: [],
    categories: [],
    customers: [],
    locations: [],
    payment_types: [],
    services: [],
    status: [],
    times: [],
    types: [],
    filter: {
      user: "",
      request: "",
      branch: "",
      invoice: "",
      location: "",
      type: "",
      status: "",
      from_date: "",
      payment_method: "",
      to_date: "",
    }
  }),
  components: {
    DynamicTable,
    Pagination,
    GenericInput,
    OptionModal
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "id", type: 'text', classes: "" },
        { text: "payment type", key: "payment_title", type: 'text', classes: "" },
        // { text: "full name", key: "user_full_name", type: 'text', classes: "" },
        // { text: "mobile", key: "mobile", type: 'text', classes: "" },
        { text: "branch", key: "branch_title", type: 'text', classes: "" },
        { text: "services", key: "services_names", type: 'text', classes: "" },
        { text: "without vat", key: "total_without_vat", type: 'price', classes: "" },
        { text: "grandTotal", key: "total_with_vat", type: 'price', classes: "" },
        { text: "option", key: "id", type: 'actions', classes: "" },

      ];
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    showDialog(row) {
      // this.optionDialog = true;
      this.selectedRow = row
    },
    optionBackMethod(status) {
      console.log(status);
      this.optionDialog = false;
      this.selectedRow = null;
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`all_invoices?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&branch=${this.filter.branch || ''}&status=${this.filter.status || ''}&payment_method=${this.filter.payment_method || ''}`).then((response) => {
        this.isLoading = false;
        this.status = response.data.status;
        this.payment_types = response.data.payment_types;
        this.branches = response.data.branchs;
        this.rows = response.data.items.data;
        this.page = response.data.items.current_page;
        this.pagination = {
          page: response.data.items.current_page,
          totalPages: response.data.items.last_page,
          per_page: response.data.items.per_page,
          totalRows: response.data.items.total,
        };
      })

    },
    edit(edit) {
      console.log(edit);
      this.$router.push(`${this.controlRoute}/` + edit.id)
    },
    cancellation(row) {
      console.log(row);
      this.isLoading = true;
      this.$api.POST_METHOD(`cancel_request/${row.id}`, this.form).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.getData()
        }
      })


    },

    printValueMethod(row) {
      const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id}?type=image"`;
      this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
